import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_tab = _resolveComponent("title-tab")!
  const _component_profile = _resolveComponent("profile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_tab, _normalizeProps(_guardReactiveProps({ currentTab: _ctx.currentTab })), null, 16),
    _createVNode(_component_profile)
  ]))
}