
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';

import EmployeeEntityModule from '@/store/employees/entity';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    FormBlock,
  },
  beforeMount() {
    this.employeeId = this.$route.params.employeeId as string;
  },
})
export default class EmployeesEntityIndex extends Form {
  employeeId = '';
  isLoading = true;

  returnBefore(): void {
    this.$router.push({ name: 'employees' });
  }

  async mounted(): Promise<void> {
    await EmployeeEntityModule.initEdit(this.employeeId);

    const entityIsLoaded = !!EmployeeEntityModule.model?.lastName;
    this.isLoading = !entityIsLoaded;
  }
}
