import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "cp-form__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_gui_loader, {
        key: 0,
        "absolute-center": ""
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_form_block, {
          "employee-id": _ctx.employeeId,
          onSave: _ctx.save,
          onReturnBefore: _ctx.returnBefore
        }, null, 8, ["employee-id", "onSave", "onReturnBefore"])
      ]))
}