
import { Component, Vue } from '@/lib/decorator';
import TitleTab from './_titleTab.vue';
import Profile from './profile/Index.vue';

import EmployeeEntityModule from '@/store/employees/entity';

@Component({
  components: {
    TitleTab,
    Profile,
  },
})
export default class EmployeeEntityEdit extends Vue {
  get currentTab(): string {
    return EmployeeEntityModule.tabsNav.profile?.id;
  }
}
